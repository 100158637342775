/* Nav.css */

@font-face {
  font-family: 'Redaction';
  src: url("/public/fonts/Redaction-Italic.woff2") format("woff2");
  font-weight: normal;
  font-style: italic;
}

* {
  text-decoration: none;
}

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent;
  color: var(--background-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  transition: 0.3s ease;
  z-index: 1000;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
}

.navbar.scrolled {
  padding: 10px 40px;
  transition: 0.7s ease;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo img {
  height: 25px;
  transition: filter 0.3s ease;
}

.logo img:hover {
  transform: scale(0.95);
  filter: blur(2px);
}

.links {
  display: flex;
  gap: 20px;
}

.links a,
.social-icons a i {
  color: var(--white-color);
  text-decoration: none;
  font-size: 20px;
  transition: none;
  mix-blend-mode: difference;
}

.links a:hover, .fullscreen-menu .menu-links a:hover {
  font-family: 'Redaction';
  color: var(--accent-color);
  filter: blur(0.7px);
  transition: .3s ease;
}

.social-icons {
  display: flex;
  gap: 15px;
}

.social-icons a i:hover, .fullscreen-menu .menu-social-icons i:hover, .menu-icon i:hover, .contact-social-icons a i:hover {
  animation: siHover .2s forwards;
  transition: none;
  filter: blur(0.8px);
}

.social-icons a i:not(:hover), .fullscreen-menu .menu-social-icons i:not(:hover), .menu-icon i:not(:hover), .contact-social-icons a i:not(:hover) {
  animation: siRevert .2s forwards;
  transition: none;
}

/*
.navbar.scrolled .links a,
.navbar.scrolled .social-icons a i {
  color: black;
}
*/

.nav-divider {
  color: var(--accent-color);
}

.links a {
  font-family: "Rubik", sans-serif;
}

.links a:hover, .fullscreen-menu .menu-links a:hover {
  animation: fontChange 0.6s forwards;
}

.links a:not(:hover), .fullscreen-menu .menu-links a:not(:hover) {
  animation: fontRevert 0.6s forwards;
}

@keyframes fontChange {
  0% {
    transform: scale(0.95);
    font-family: "Rubik", sans-serif;
  }
  20% {
    font-family: "Rubik", sans-serif;
    font-style: italic;
  }
  30% {
    font-family: 'Redaction', sans-serif;
  }
}

@keyframes fontRevert {
  0% {
    font-family: "Redaction", sans-serif;
    color: var(--accent-color);
  }
  5% {
    font-family: "Rubik", sans-serif;
    font-style: italic;
    color: var(--accent-color);
    transform: scale(1.05);
  }
  10% {
    font-family: 'Rubik', sans-serif;
  }
}

@keyframes siHover {
  0% {
    color: var(--white-color);
  }
  50% {
    color: var(--accent-color);
    transform: scale(1.2);
    font-weight: bolder;
  }
  100% {
    color: #dcf263;
    transform: scale(1.1);
    font-style: italic;
  }
}

@keyframes siRevert {
  0% {
    color: var(--accent-color);
    transform: scale(1.1);
    font-style: italic;
  }
  50% {
    color: var(--accent-color);
    transform: scale(1.2);
    font-weight: bolder;
  }
  100% {
    color: white;
  }
}

@keyframes fontChange {
  0% {
    transform: scale(0.95);
    font-family: "Rubik", sans-serif;
  }
  20% {
    font-family: "Rubik", sans-serif;
    font-style: italic;
  }
  30% {
    font-family: 'Redaction', sans-serif;
  }
}
