/* root variables */

:root {
  --accent-color: #dcf263;
  --white-color: white;
  --background-color: black;
}

/* global styles */

html, body {
  height: 100%;
  width: 100%;
}

* {
  cursor: none;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

html {
  background-color: var(--background-color);
  color: var(--white-color);
  cursor: none;
}

*::selection {
  color: var(--background-color);
  background-color: var(--accent-color);
}

body {
  margin: 0;
  font-family: "Rubik", sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
}

.uncap {
  text-transform: none;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in-out;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

/* cursor */

.custom-cursor {
  width: 16px;
  height: 16px;
  background-color: transparent;
  border: 1px solid var(--white-color);
  border-radius: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.2s ease, background-color 0.4s ease, width 0.4s ease, height 0.4s ease, border 0.4s ease-in-out, opacity 0.5s ease;
  transform: translate(-50%, -50%);
}

.custom-cursor.hover {
  width: 70px;
  height: 70px;
  border: 2px solid var(--accent-color);
  background-color: transparent;
  opacity: 1;
  filter: blur(2px);
  transition: transform 0.4s ease, border 0.4s ease, width 0.4s ease, height 0.4s ease, opacity 0.3s ease;
}

.custom-cursor {
  opacity: 0;
}

/* / header */

.parallax-header {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.hero {
  position: relative;
  z-index: 2;
  color: var(--white-color);
  font-size: 2rem;
  text-align: center;
  width: 100%;
}

.parallax-header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1;
  border: none;
  overflow: hidden;
}

.accents {
  position: absolute;
  top: 50vh;
  left: 0;
  right: 0;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  font-size: 20px;
  text-align: left;
}

.accent-1,
.accent-2,
.accent-3 {
  display: flex;
}

.accent-3>ul {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.accent-3 img {
  width: 100%;
  max-width: 120px;
  margin-left: 25px;
}

.ph-font {
  font-family: 'Redaction';
  text-transform: none;
  font-weight: 100;
  color: var(--color-accent, var(--accent-color));
}

.ph-icon {
  color: var(--color-accent, var(--accent-color));
}

.hero h1 .letter,
.hero h1 span {
  display: inline-block;
  will-change: transform, opacity;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
}

.mobile-portfolio-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  padding: 0;
  margin: 0;
  width: 100vw;
}

/* / desktop projects */

.works-section {
  position: relative;
}

.works-section h2 {
  font-size: 15px;
  padding: 2rem;
}

.work-vaw {
  color: white;
  font-size: 15px;
  padding: 2rem;
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.work-vaw:hover {
  color: var(--accent-color);
  transition: .3s ease;
}

.work-vaw a:hover {
  animation: workFontChange 0.6s forwards;
}

.work-vaw a.active {
  color: var(--accent-color);
}

.work-vaw a:not(.active) {
  animation: workFontRevert 0.6s forwards;
}

.works-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.work-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  border: 1px solid transparent;
  transition: background-color 0.3s, border-color 0.3s;
  position: relative;
  transition: .3s ease;
}

.work-item:hover {
  border-top: 1px var(--white-color) solid;
  border-bottom: 1px var(--white-color) solid;
  padding: 2rem;
  transition: .3s ease;
}

.left-text,
.right-text {
  color: var(--white-color);
  flex: 0 0 auto;
  font-size: 15px;
  width: 150px;
}

.left-text {
  text-align: left;
}

.right-text {
  text-align: right;
}

.work-item h1 {
  color: var(--white-color);
  margin: 0;
  flex-grow: 1;
  text-align: center;
  min-width: 200px;
}

.work-item.active .left-text,
.work-item.active .right-text,
.work-item.active h1 {
  color: var(--accent-color);
}

.work-item.active {
  animation: workFontChange 0.6s forwards;
}

.work-item:hover {
  font-family: 'Redaction';
  color: var(--accent-color);
  transition: .3s ease;
}

.work-item:not(.active) {
  animation: workFontRevert 0.6s forwards;
}

.hover-image {
  width: 400px;
  height: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
  position: absolute; 
  z-index: 10;
}

.work-item:hover .hover-image {
  opacity: 1;
}

/* / mobile portfolio */

.mobile-portfolio-item {
  position: relative;
  overflow: hidden;
}

.mobile-portfolio-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: .3s ease;
  overflow: hidden;
}

.mobile-portfolio-item h3 {
  position: absolute;
  top: 0;
  left: 0;
  color: var(--white-color);
  padding: 40px 40px;
  margin: 0;
  text-align: center;
  overflow: hidden;
}

.mobile-portfolio-item {
  border: solid 0px var(--accent-color);
  transition: .3s ease;
  overflow: hidden;
}

.mobile-portfolio-item:hover {
  overflow: hidden;
  border: solid 25px var(--accent-color);
  transition: .3s ease;
}

.mobile-portfolio-item img {
  transition: transform 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.mobile-portfolio-item:hover img {
  transform: scale(1.05);
}

.portfolio-slider {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
  border-top: solid 1px var(--white-color);
  border-bottom: solid 1px var(--white-color);
}

.slider-text {
  display: inline-flex;
}

.slider-text p {
  margin-left: 10px;
}

.rotating-logo {
  position: relative;
  top: -20px;
  width: 130px;
  margin-left: 25px;
}

.rotating-logo img {
  position: absolute;
  width: 100%;
  height: auto;
  transition: opacity 1s ease-in-out;
}

.menu-icon {
  display: none;
  font-size: 32px;
  cursor: pointer;
  color: var(--white-color);
  z-index: 9999;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  z-index: 1000;
}

.fullscreen-menu .menu-links {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 80px;
}

.fullscreen-menu .menu-links a {
  color: var(--white-color);
  font-size: 42px;
  text-decoration: none;
  text-align: center;
  font-weight: bold;
  transition: color 0.3s ease;
}

.fullscreen-menu .menu-social-icons i {
  color: var(--white-color);
  display: inline-flex;
  margin: 0 20px;
  font-size: 42px;
}

.fullscreen-menu.open {
  opacity: 1;
  visibility: visible;
}

.navbar .menu-social-icons {
  display: none;
}

.mobile-portfolio-grid {
  display: none;
  overflow: hidden;
}

.contact-section {
  padding-left: 2rem;
  padding-right: 2rem;
}

.contact-section h3 {
  max-width: 40%;
}

.contact-section span {
  font-family: "Redaction";
  text-transform: none;
  font-size: 32px;
  color: var(--accent-color);
}

.promo-section-one {
  padding: 2rem;
}

.promo-section-one h1 {
  max-width: 70%;
  font-size: 4.5rem;
  position: relative;
  line-height: 150%;
}

.promo-letter {
  display: inline-block; 
  will-change: transform, opacity;
}

.color {
  color: var(--accent-color);
}

.promo-section-one {
  padding: 2rem;
  display: flex; 
  justify-content: space-between;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
  align-items: center;
}

.pso-img {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
  position: relative;
  top: 0;
  left: -3rem;
  perspective: 1000px;
}

.pso-img img {
  max-width: 19rem;
  height: auto;
}

.pso-img-1 {
  position: relative;
  left: -150px;
  top: 50px;
  transform: rotate(5deg);
  z-index: -1;
}

.pso-img-2 {
  transform: rotate(-5deg);
  z-index: -2;
}

.promo-section-one h1 {
  z-index: 1;
}

/* /about */

.about-landing {
  width: 100%;
  height: 100%;
  align-content: center;
  text-transform: none;
}

.experience-section {
  padding: 2rem;
}

.experience-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.experience-container p {
  color: var(--accent-color);
}

.scope-slide-container {
  display: flex;
  white-space: nowrap;
}

.figures-section {
  padding: 2rem;
}

.figures-section p {
  color: var(--accent-color);
}

.figures-section {
  text-align: left;
}

.figures-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.figures1, .figures2, .figures3 {
  flex: 1;
  min-width: 200px;
}

.font {
  font-family: "Redaction";
  text-transform: none;
}

.about-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  height: 100vh;
}

.about-landing {
  flex: 1;
  max-width: 50%;
}

.about-landing h1 {
  color: var(--background-color);
  background-color: var(--accent-color);
  display: inline-block;
}

.about-landing h1::selection {
  background-color: var(--white-color);
}

.about-landing p {
  line-height: 1.5;
}

.about-landing a {
  color: var(--accent-color);
}

.about-landing a:hover {
  text-decoration: underline;
}

.about-img {
  flex: 1;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.about-img img {
  width: 100%;
  height: auto;
  max-height: 90vh;
  object-fit: cover;
}

/* /contact form */

.form-wrapper {
  display: flex;
  height: 100vh;
}

.form-header {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: none;
}

.form-header h1 {
  margin-bottom: 1rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  text-transform: none;
  font-family: "Redaction";
  color: var(--accent-color);
}

.form-header p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}

.form-container {
  flex: 1;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

label {
  font-weight: bold;
}

input,
textarea {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  max-width: 100%;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: 0.3s ease;
  box-sizing: border-box;
  background-color: var(--background-color);
  color: var(--white-color);
}

textarea::-webkit-resizer {
  color: var(--white-color);
}

textarea:-moz-resizer {
  color: var(--white-color);
}

input:focus,
textarea:focus {
  border-color: var(--accent-color);
  outline: none;
}

input:hover, textarea:hover {
  border-color: var(--accent-color);
}

button {
  font-family: "Rubik", sans-serif;
  font-weight: bol;
  padding: 10px 15px;
  background-color: var(--background-color);
  border: solid 1px var(--accent-color);
  color: var(--white-color); 
  font-size: 16px;
  margin-top: 1rem;
  transition: 0.3s ease;
  border-radius: 5px;
}

button:hover {
  background-color: var(--accent-color);
  color: var(--background-color);
  border-radius: 5px;
}

.email-hover:hover {
  text-decoration: underline;
}

.contact-social-icons a i {
  color: var(--white-color);
  font-size: 30px;
  margin-right: 1rem;
  padding-top: 1rem;
}

/* photography */

.photo-grid {
  padding: 2rem;
  padding-top: 4rem;
}

.photo-grid-row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
}

.photo-grid-column {
  flex: 15%;
  padding: 0 4px;
}

.photo-grid-column img {
  margin-top: 8px;
  width: 100%;
  vertical-align: middle;
}

/* lenovo */

.lenovo-section-one {
  padding: 2rem;
  padding-top: 4rem;
  height: 150vh;
  flex: 1;
}

.lenovo-media-section {
  padding: 2rem;
}

.iframe-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 75%;
  height: 75%;
}

/* nova */

.nova-image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-top: 20px;
}

.nova-image-grid img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.nova-video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;
  background: black;
  margin: 0 auto; 
}

.nova-responsive-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* warriors */

.warriors-landing {
  padding: 2rem;
  padding-top: 4rem;
  height: 30vh;
  align-items: center;
  padding-bottom: 0;
}

.warriors-grid1 {
  display: flex;
  padding: 2rem;
  padding-top: auto;
  max-width: 75%;
  
}

.warriors-desc {
  padding: 2rem;
}

.warriors-desc-grid {
  display: block;
  justify-content: center;
}

.warriors-desc-grid img {
  max-width: 100%;
}

/* responsive */

@media (max-width: 768px) {
  .nova-image-grid {
    grid-template-columns: 1fr 1fr; 
  }
}

@media (max-width: 480px) {
  .nova-image-grid {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 1500px) {
}

@media only screen and (max-width: 1200px) {
  .accents {
    display: none;
  }

  .hero h1 {
    font-size: 35px;
  }

  .mobile-portfolio-grid {
    display: grid;
    max-width: 100%;
}

.works-section {
    display: none;
}

  .promo-section-one h1 span {
    font-size: 45px;
  }

  .promo-section-one h1 {
    line-height: 90%;
  }
}

@media only screen and (max-width: 800px) {
  .promo-section-one h1 span {
    font-size: 35px;
  }

  .promo-section-one h1 {
    line-height: 60%;
    max-width: 100%;
  }

  .pso-img {
    position: absolute;
    display: hidden;
    opacity: 0;
  }

  .figures-container {
    display: block;
    justify-content: center;
    gap: 20px;
  }

  .figures1, .figures2, .figures3 {
    display: block;
  }

  .form-wrapper {
    flex-direction: column;
    height: auto;
  }

  .form-header,
  .form-container {
    flex: none;
    width: 90vw;
  }

  .photo-grid-column {
    flex: 30%;
    padding: 0 4px;
  }

  .about-landing {
    max-width: 100%;
  }

  .about-img {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .custom-cursor {
    display: none;
  }

  .accents {
    display: none;
  }

  .hero h1 {
    font-size: 23px;
  }

  * {
    cursor: auto;
  }

  .navbar .links {
    display: none;
  }

  .navbar .social-icons {
    display: none;
  }

  .menu-icon {
    display: block;
  }

  .navbar .menu-social-icons {
    display: block;
  }

  .navbar .logo {
    z-index: 2222;
  }

  .mobile-portfolio-grid {
    display: block;
  }

  .promo-section-one h1 span {
    font-size: 45px;
  }

  .promo-section-one {
    width: 80%;
  }

  .promo-section-one h1 {
    line-height: 45%;
    max-width: 100%;
  }

  .pso-img {
    display: hidden;
    opacity: 0;
  }

  .photo-grid-column {
    flex: 40%;
    padding: 0 4px;
  }
}

/* animations */

@keyframes workFontChange {
  0% {
    font-family: "Rubik", sans-serif;
  }
  20% {
    font-family: "Rubik", sans-serif;
    font-style: italic;
  }
  30% {
    font-family: 'Redaction', sans-serif;
  }
}

@keyframes workFontRevert {
  0% {
    font-family: "Redaction", sans-serif;
    color: var(--accent-color);
  }
  5% {
    font-family: "Rubik", sans-serif;
    font-style: italic;
    color: var(--accent-color);
  }
  10% {
    font-family: 'Rubik', sans-serif;
  }
}